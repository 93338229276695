<template>
  <div>
    <h2 class="blue-grey--text nunito lighten-3 text-capitalize">
      <b>activate payment gateway</b>
    </h2>
    <v-divider class="my-2"></v-divider>
    <h4 class="blue-grey--text nunito">
      Fill in only one of the payment gateway detail
    </h4>
    <v-container>
      <v-row>
        <v-col cols="6" class="pa-0">
          <v-card-title primary-title class="blue-grey--text">
            ipay
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="ipay key"
              outlined
              dense
              :value="merchant_detail.ipay_key"
            ></v-text-field>
            <v-text-field
              label="ipay code"
              outlined
              dense
              :value="merchant_detail.ipay_code"
            ></v-text-field>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col cols="6" class="pa-0">
          <v-card-title primary-title class="blue-grey--text">
            billplz
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="billpliz collection"
              outlined
              dense
              :value="merchant_detail.billplz_collection"
            ></v-text-field>
            <v-text-field
              label="billpliz token"
              outlined
              dense
              :value="merchant_detail.billplz_token"
            ></v-text-field>
            <v-text-field
              label="billpliz mac"
              outlined
              dense
              :value="merchant_detail.billplz_mac"
            ></v-text-field>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col cols="6" class="pa-0">
          <v-card-title primary-title class="blue-grey--text">
            kipple
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="kipple key"
              outlined
              dense
              :value="merchant_detail.kiple_key"
            ></v-text-field>
            <v-text-field
              label="kipple code"
              outlined
              dense
              :value="merchant_detail.kiple_code"
            ></v-text-field>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <div class="flex justify-end mr-3 mb-3">
      <v-btn color="success">save</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "payment",

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      merchant_detail: "getImpersonate",
    }),
  },

  // beforeMount() {
  //   console.log("merchant", this.merchant_detail);
  // },
};
</script>